var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fondoGrisClaro",attrs:{"id":"section4"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-container',{staticClass:"text-center"},[_c('div',{staticStyle:{"margin":"0 auto"}},[_c('carousel',{attrs:{"autoplay":true,"autoplay-timeout":2000,"autoplay-hover-pause":true,"loop":true,"per-page-custom":[
            [320, 1],
            [375, 1],
            [480, 1],
            [768, 3],
            [980, 3],
            [1500, 4]
          ]}},_vm._l((_vm.list),function(item,idx){return _c('slide',{key:idx},[_c('div',{staticClass:"box"},[_c('h1',[_vm._v(_vm._s(item.title))]),_c('strong',{directives:[{name:"show",rawName:"v-show",value:(item.title1 != ''),expression:"item.title1 != ''"}],staticClass:"giro90"},[_vm._v(_vm._s(item.title1))]),_c('h3',{staticClass:"mt-3"},[_c('strong',[_vm._v(_vm._s(item.text))])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.text1 != ''),expression:"item.text1 != ''"}]},[_vm._v(" "+_vm._s(item.text1)+" ")])])])}),1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }