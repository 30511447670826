<template name="lideresSection11">
  <div
    id="section11"
    class="fondoGrisClaro py-4"
  >
    <div
      class="ranking rank-home py-2"
      style="position:relative"
    >
      <div class="container p-4">
        <p class="text-center">
          <svg
            id="animateDown"
            data-v-fcd84ec0
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-down"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            class="cyanGreen animateDown bounce svg-inline--fa fa-angle-down fa-w-10"
          ><path
            fill="currentColor"
            d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
            class=""
          /></svg>
        </p>
        <h3 class="bg">
          EL CLUB
        </h3>
        <h2>CRECIMIENTO<span>AYER, HOY, SIEMPRE</span></h2>
        <div class="row">
          <div class="col-xs-12 col-md-4">
            <h4 class="text-center">
              SOLIDARIO
            </h4>
            <p class="text-center">
              Basado en la economia colaborativa, Internacional y con espíritu solidario. Te ayudamos a crecer
            </p>
            <p class="text-center rank-nombre">
              Para crecer juntos
            </p>
          </div>
          <div class="col-xs-12 col-md-4">
            <h4 class="text-center">
              VISIONARIO
            </h4>
            <p class="text-center">
              Único en su modelo, tecnologico, actual, dinámico y con salidos valores para cambiar el mundo de hoy
            </p>
            <p class="text-center rank-nombre">
              Para llegar lejos
            </p>
          </div>
          <div class="col-xs-12 col-md-4">
            <h4 class="text-center">
              LÍDER
            </h4>
            <p class="text-center">
              Estar en el top ten de Smart Organizations con el conocimiento científico más avanzado en negocios
            </p>
            <p class="text-center rank-nombre">
              Para inspirar el cambio
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        texts() {
            return this.$t('masteresPosgrados.section6.texts');
        }
    }
};
</script>

<style lang="scss">
.cyanGreen {
	color: #0070a9;
}
svg:not(:root).svg-inline--fa {
	overflow: visible;
}
svg:not(:root).svg-inline--fa {
	overflow: visible;
}
.ranking {
	.fa-angle-down {
		font-size: 100px;
		line-height: 80px;
	}
	.container {
		background: #fff;
	}
	.ranking.rank-home p {
		font-family: EncodeSansSemiExpanded-Medium, sans-serif;
		font-size: 16px;
		color: #000;
	}
	p,
	li {
		padding: 0 8%;
		font-size: 12px;
		font-family: EncodeSansSemiExpanded-Medium, sans-serif;
	}
	h3.bg {
		position: absolute;
		text-align: center;
		color: #f1f3f6;
		font-family: KelsonSans-Bold, sans-serif;
		font-size: 180px;
		margin-top: 0;
		left: 50%;
		margin-left: unset;
		-webkit-transform: translate(-50%);
		transform: translate(-50%);
	}
	h2 {
		text-align: center;
		font-family: KelsonSans-Bold, sans-serif;
		font-size: 36px;
		position: relative;
		margin: 0 0 67px 0;
		span {
			display: block;
			font-family: EncodeSansSemiExpanded-Medium, sans-serif;
			font-size: 14px;
		}
	}
}
.ranking.rank-home h4 {
	font-size: 48px;
	font-family: KelsonSans-Bold, sans-serif;
	color: #002357;
}
.ranking.rank-home p {
	font-family: EncodeSansSemiExpanded-Medium, sans-serif;
	font-size: 16px;
	color: #000;
}
.ranking li,
.ranking p {
	padding: 0 8%;
}
.ranking.rank-home p.rank-nombre {
	font-family: KelsonSans-Bold, sans-serif;
	color: #002357;
}
.bounce {
	-moz-animation: bounce 2s infinite;
	-webkit-animation: bounce 2s infinite;
	animation: bounce 2s infinite;
}
@-moz-keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-moz-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-moz-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-moz-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
@-webkit-keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-moz-transform: translateY(-30px);
		-ms-transform: translateY(-30px);
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-moz-transform: translateY(-15px);
		-ms-transform: translateY(-15px);
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}
</style>
