<template>
  <div id="llamada">
    <div>
      <b-modal
        id="mllamadas"
        size="xl"
        no-fade
        hide-footer
        :title="title"
        scrollable
        centered
        class="fondoGrisClaro"
      >
        <b-container
          fluid
          class="text-center"
        >
          <h2>
            {{ $t('masteresPosgrados.llamadas.title2') }}
          </h2>
          <h4>
            {{ $t('masteresPosgrados.llamadas.title3') }}
          </h4>
          <br>
          <Llamada />
        </b-container>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Llamada from '../utils/Llamada';

export default {
    components: {
        Llamada
    },
    computed: {
        title() {
            return this.$t('masteresPosgrados.llamadas.title');
        }
    }
};
</script>

<style lang="scss">
#mllamadas {
    .modal-dialog {
        width: auto !important;
        z-index: 2000 !important;
    }
}
</style>
