<template>
  <div
    id="section2"
    class="fondoGrisClaro"
  >
    <b-container
      id="empezar"
      fluid
    >
      <b-container class="text-center mt-3 mb-3">
        <h2>
          <strong>{{
            $t('practicasEnEmpresas.section2.title')
          }}</strong>
        </h2>
        <h3>{{ $t('practicasEnEmpresas.section2.text') }}</h3>
        <br>
        <b-row align-h="center">
          <b-col
            v-for="(item, index) in lists"
            :key="index"
            md="3"
            sm="12"
          >
            <div
              class="cardd mb-2 mt-2"
              data-aos="flip-up"
            >
              <b-link
                :href="preUrl + item.url"
                target="_blank"
              >
                <b-img
                  :src="listsU[index].img"
                  :alt="item.nombre"
                  class="mb-2"
                />
                <h3 class="cardd-title">
                  {{ item.nombre }}
                </h3>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            preUrl: process.env.VUE_APP_WEB,
            listsU: [
                {
                    img: require('@/assets/images/mp/masters/masteres.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/marketing.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/diseno-web.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/programacion-base.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/ofimatica.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/nuevas-tecnologias.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/business-management.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/recursos-humanos.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/habilidades-directivas.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/sistemas-operativos.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/comercial-ventas.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/administracion-publica.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/hosteleria-restauracion.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/contabilidad-finanzas.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/normativa-fiscalidad.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/seguros.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/prevencion-labores.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/alimentacion.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/salud-nutricion.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/educacion.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/idiomas.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/practicas-en-empresas.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/superiores.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/expertos.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/especialistas.jpg')
                },
                {
                    img: require('@/assets/images/mp/masters/otros-cursos.jpg')
                }
            ]
        };
    },
    computed: {
        lists() {
            return this.$t('masteresPosgrados.section2.lists');
        }
    }
};
</script>

<style lang="scss">
#section2 {
    .cardd {
        background: rgba($color: #000000, $alpha: 0.1);
        height: calc(95%);
        padding: 0 0 10px 0;
        transition: 0.3s;
        .cardd-title {
            font-size: 18px;
        }
    }
}
</style>
