<template>
  <div id="section5">
    <div class="FondoFNegro py-5">
      <b-container fluid>
        <b-container class="text-center mt-3 mb-3">
          <b-row>
            <b-col sm="12">
              <h2
                class="mt-5 mb-2"
                style="color:#fff"
              >
                {{ $t('masteresPosgrados.section5.title') }}
              </h2>
              <br>
            </b-col>
            <b-col
              v-for="(item, index) in lists"
              :key="index"
              md="4"
              sm="12"
            >
              <div
                class="m-4 p-1 cardd"
                data-aos="zoom-in-up"
                data-aos-easing="ease-in-sine"
                data-aos-anchor-placement="bottom-bottom"
              >
                <h3>
                  <strong>
                    {{ item.title }}
                    <br>
                    ---
                  </strong>
                </h3>
                <strong>
                  {{ item.text }}
                </strong>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        lists() {
            return this.$t('masteresPosgrados.section5.lists');
        }
    }
};
</script>

<style lang="scss">
#section5 {
	.cardd {
		height: calc(80%);
		background-color: rgba(0, 0, 0, 0.3);
		padding: 1em !important;
		transition: 0.3s;
	}
	.cardd:hover {
		transform: scale(1.1);
	}
}
</style>
