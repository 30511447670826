<template>
  <div
    id="section4"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="text-center">
        <div style="margin: 0 auto;">
          <carousel
            :autoplay="true"
            :autoplay-timeout="2000"
            :autoplay-hover-pause="true"
            :loop="true"
            :per-page-custom="[
              [320, 1],
              [375, 1],
              [480, 1],
              [768, 3],
              [980, 3],
              [1500, 4]
            ]"
          >
            <slide
              v-for="(item, idx) in list"
              :key="idx"
            >
              <div class="box">
                <h1>{{ item.title }}</h1>
                <strong
                  v-show="item.title1 != ''"
                  class="giro90"
                >{{ item.title1 }}</strong>
                <h3 class="mt-3">
                  <strong>{{ item.text }}</strong>
                </h3>
                <p v-show="item.text1 != ''">
                  {{ item.text1 }}
                </p>
              </div>
            </slide>
          </carousel>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<i18n>
{
}
</i18n>

<script>
export default {
    data() {
        return {};
    },
    computed: {
        list() {
            return this.$t('masteresPosgrados.section4.lists');
        }
    }
};
</script>

<style lang="scss">
#section4 {
    .box {
        position: relative;
        h1 {
            font-size: 4.5em !important;
            position: relative;
        }
        @media (max-width: 900px) {
            h1 {
                font-size: 3.5em !important;
            }
        }
        @media (max-width: 480px) {
            h1 {
                font-size: 3em !important;
            }
        }
    }
    .giro90 {
        position: absolute;
        top: 1.5em;
        right: 0;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);

        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
}
</style>
