<template>
  <div
    id="section9"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-3 text-center">
        <h2>{{ $t('masteresPosgrados.section9.title') }}</h2>
        <br>
        <b-row align-h="center">
          <b-col
            v-for="(item, index) in lists"
            :key="index"
            lg="3"
            md="4"
            sm="12"
          >
            <div
              class="cardd mb-3 mt-3"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              <div class="carddPais">
                <strong>{{ item.pais }}</strong>
              </div>
              <div class="divImg">
                <b-img :src="listsImg[index]" />
              </div>
              <div>
                <strong>{{ item.nombre }}</strong>
                <p>{{ item.cargo }}</p>
              </div>
              <div>
                <b-button v-scroll-to="'#contactForm'">
                  {{ $t('masteresPosgrados.section9.btn') }}
                </b-button>
              </div>
              <br>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<i18n>
{
}
</i18n>

<script>
export default {
    data() {
        return {
            listsImg: [
                require('@/assets/images/mp/contacto/Jose-Manuel-Cosio.png'),
                require('@/assets/images/mp/contacto/Susana-Prieto-Heredia.png'),
                require('@/assets/images/mp/contacto/Elena-Zalbide-Elorduy.png'),
                require('@/assets/images/mp/contacto/Roberto-Ruiz-Yecora.png'),
                require('@/assets/images/mp/contacto/Carolina-Salvador-Moliner.png'),
                require('@/assets/images/mp/contacto/Jose-Manuel-Martinez.png'),
                require('@/assets/images/mp/contacto/Pedro-Pimentel.png'),
                require('@/assets/images/mp/contacto/Pedro-Luis-Alonso-Tuda.png'),
                require('@/assets/images/mp/contacto/Miguel-Angel-Osuna.png'),
                require('@/assets/images/mp/contacto/Antonio-Jose-Castro-Hernandez.png'),
                require('@/assets/images/mp/contacto/Mikel-Anakabe-Ondarre.png')
            ]
        };
    },
    computed: {
        lists() {
            return this.$t('masteresPosgrados.section9.lists');
        }
    }
};
</script>

<style lang="scss">
#section9 {
    .cardd {
        text-align: center;
        transition: 0.3s;
        height: calc(90%);

        .carddPais {
            background-color: rgba(0, 0, 0, 0.3);
        }
        .divImg {
            padding: 1em;
            height: 11em;
            width: 100%;
            img {
                height: 10em;
                width: 10em;
                margin: 0 auto !important;
            }
        }
    }
    .cardd:hover {
        transform: scale(1.1);
    }
}
</style>
