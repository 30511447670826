<template>
  <div
    id="section7"
    class="fondoGrisOscuro"
  >
    <b-container fluid>
      <b-container class="mt-3 mb-3">
        <tyt
          :titles="title"
          activo="false"
          :videos="videosID"
        />
      </b-container>
    </b-container>
  </div>
</template>

<script>
import tyt from '../utils/temp_youtube';
export default {
    components: {
        tyt
    },
    data() {
        return {
            videosID: ['6X-BK6puwvc', 'F4QOqU_zFbQ', 'WkM5XiMGwq0']
        };
    },
    computed: {
        title() {
            return this.$t('masteresPosgrados.section7.title');
        }
    }
};
</script>

<style lang="scss"></style>
