<template>
  <div id="lateral">
    <div
      v-responsive.md.lg.xl
      class="HeadVertical-center"
    >
      <div class="cuadro">
        <div
          v-b-tooltip.left.hover
          class="iconol"
          :title="$t('message.call')"
          @click="$bvModal.show('mllamadas')"
        >
          <b-img :src="img1" />
        </div>
      </div>
      <div class="cuadro">
        <div
          v-b-tooltip.left.hover
          class="iconol"
          :title="$t('message.opend')"
          @click="menuClicked('MasteresPosgradosOpenDay')"
        >
          <b-img :src="img2" />
        </div>
      </div>
      <div class="cuadro">
        <div
          v-b-tooltip.left.hover
          class="iconol"
          :title="$t('message.admins')"
          @click="menuClicked('MasteresPosgradosProcesoAdmission')"
        >
          <b-img :src="img3" />
        </div>
      </div>
      <div class="cuadro">
        <div
          v-scroll-to="'#contactForm'"
          v-b-tooltip.left.hover
          class="iconol"
          :title="$t('message.contact')"
        >
          <b-img :src="img4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            img1: require('@/assets/images/mp/Llamanos.png'),
            img2: require('@/assets/images/mp/Open Day.png'),
            img3: require('@/assets/images/mp/Proceso de Admision.png'),
            img4: require('@/assets/images/mp/Contactanos.png')
        };
    },
    methods: {
        menuClicked(itemName) {
            this.$router.push({ name: itemName });
        }
    }
};
</script>

<style lang="scss">
#lateral {
    color: whitesmoke;

    .HeadVertical-center {
        position: fixed;
        right: 0;
        width: 4em;
    }
    .cuadro {
        background: rgba($color: #015fff, $alpha: 0.75);
        margin: 0.25em 0;
        height: 4em;
        cursor: pointer;

        .iconol {
            padding: 0.5em;
        }
    }
}
</style>
