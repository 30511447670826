<template>
  <div class="wrapper custom-landing">
    <Lateral />
    <div id="mp">
      <Section1 />
      <Section2 />
      <Section4 />
      <Section5 />
      <Section11 />
      <Section7 />
      <Section9 />
      <Section10 />
    </div>
    <Llamadas />
  </div>
</template>

<script>
import Lateral from './components/Lateral';
import Llamadas from './components/llamadas/Llamadas';
import Section1 from './components/mp/Section1';
import Section2 from './components/mp/Section2';
import Section4 from './components/mp/Section4';
import Section5 from './components/mp/Section5';
import Section7 from './components/mp/Section7';
import Section9 from './components/mp/Section9';
import Section10 from './components/mp/Section10';
import Section11 from './components/mp/Section11';
export default {
    name: 'PracticasEnEmpresas',
    components: {
        Lateral,
        Llamadas,
        Section1,
        Section2,
        Section4,
        Section5,
        Section7,
        Section9,
        Section10,
        Section11
    },
    head: {
        title: {
            inner: 'Másters & Postgrados'
        }
    },
    data() {
        return {
            checkedNames: [],
            form: {
                name: '',
                email: '',
                number: '',
                situation: '',
                donde: '',
                message: '',
                checked: 'He leído y acepto las políticas de privacidad'
            },
            status: 'not_accepted',
            sentSuccess: false,
            sentFailed: false
        };
    },
    methods: {
        onSubmit() {
            event.preventDefault();
            this.axios
                .post('/api/familia_seguraContact.php', this.form)
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                    }
                })
                .catch(() => {
                    this.sentFailed = true;
                });
        }
    }
};
</script>

<style scoped lang="scss">
@import '../../assets/css/stlProyecto.css';
.wrapper {
    div > img {
        max-width: 100%;
    }
}
</style>
