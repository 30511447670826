<template>
  <div id="section">
    <div class="fondoGrisOscuro">
      <b-container fluid>
        <b-container class="text-center mt-1 mb-1">
          <h2>
            <span
              v-for="(item, idx) in titles"
              :key="idx"
            >
              <strong v-if="item.type == 'strong'">
                {{ item.value }}
              </strong>
              <span v-else>
                {{ item.value }}
              </span>
            </span>
          </h2>
          <br>
          <b-row
            align-h="center"
            align-v="center"
          >
            <b-col
              v-for="(item, idx) of videos"
              :key="idx"
              lg="4"
              md="6"
              sm="12"
              class="text-center"
            >
              <yt
                :key="idx"
                :idvideo="item"
                :activo="activo"
              />
              <br>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<script>
import yt from './youtube';
export default {
    components: {
        yt
    },
    props: {
        titles: {
            default: () => [],
            type: Array
        },
        videos: {
            default: () => [],
            type: Array
        },
        activo: {
            default: '',
            type: String
        }
    }
};
</script>
