<template>
  <div
    id="section10"
    class="fondoGrisClaro"
  />
</template>

<i18n>
{
}
</i18n>

<script>
export default {
    data() {
        return {
            preUrl: process.env.VUE_APP_WEB
        };
    },
    computed: {
        lists() {
            return this.$t('masteresPosgrados.section10.lists');
        }
    }
};
</script>

<style lang="scss">
#section10 {
    .s10border {
        border: 1px dotted black;
        padding: 0.5em 0.25em;
        margin: 0.5em;
        text-align: center;

        @media (max-width: 425px) {
            margin: 0.5em 0.2em;
            padding: 0.5em 0;
        }
    }
}
</style>
